@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: 0 0% 85%;
    --input: 0 0% 85%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    /*--primary: 222.2 47.4% 11.2%;*/
    --primary: 228 72% 61%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 108 100% 36%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }

  .text-sm {
    font-size: 14px;
    line-height: 160% !important;
  }
}

/* add the code bellow */
@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }

    .w-inherit {
      width: inherit;
    }
  }
}

.editor-text p {
  margin: 0;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@keyframes dots {
  0% {
    content: "";
  }
  50% {
    content: ".";
  }
  66% {
    content: "..";
  }
  100% {
    content: "...";
  }
}

.animate-dots::after {
  content: "";
  animation: dots 1500ms steps(1, end) infinite;
}

.editor-component h3:not(:last-child) {
  margin-bottom: -16px;
}

.carousel-home-page .control-dots {
  bottom: -16px !important;
}

button[data-state="open"] .ChevronDown {
  transition: transform 500ms;
}

button[data-state="open"] .ChevronDown {
  transform: rotate(180deg);
}

#activity-outline h3 {
  margin: auto;
}
.border-dashed-b {
  background-image: linear-gradient(
    to right,
    #d8dae2 50%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: bottom;
  background-size: 12px 1px;
  background-repeat: repeat-x;
}

.home-slider .slick-dots .slick-active .dot {
  background-color: #304a99;
}

/* css chat-paragraphs */

.chat-paragraphs h1 {
  font-size: 24px;
  line-height: 36px;
  font-weight: 600;
  color: #0F183B;
}

.chat-paragraphs h3 {
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
}

.chat-paragraphs strong {
  font-weight: 600;
}

.chat-paragraphs ul {
  list-style: disc;
  margin-left: 20px;
}

.loading-paragraphs {
  max-width: calc(100vw - 40px);
  overflow: hidden;
}

.home-chat-slider .slick-slide {
  max-width: 350px !important;
}

.message-room .chat-text {
  padding-bottom: 0px ;
}